import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "../styles/VoiceSetting.css";
import { useDispatch, useSelector } from "react-redux";
import { errorReset } from "../store/actions/handleHome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { stateChange } from "../functions/stateChange";
import { CeckConnection } from "../functions/ConnectionStatius";
import soudError from "../sound/error.wav";
import { fetchVoice, voiceAdd } from "../store/actions/handelVoiceSetting";
import VoiceCardSetting from "../components/voiceSettingComponent/VoiceCardSetting";
import ButtonAdd from "../components/globalComponent/ButtonAdd";
import Navbar from "../components/Navbar";
import { Box, Typography, IconButton, Tooltip, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";

registerLocale("it", it);
function VoiceSetting() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userToken = useSelector((state) => state.authReducer.token);

  const { error, message, success, arrVociCassa } = useSelector((state) => state.voiceSettingReducer);

  // audio errore
  const audio = new Audio(soudError);
  audio.loop = false;
  const playError = () => {
    audio.loop = false;
    audio.play();
  };
  // ----------------------------------------------------------------

  // tost message errore
  const notifyError = () =>
    toast.error(message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // -----------------------------
  // tost message errore
  const notifySuccess = () =>
    toast.success("Salvato con sucesso !!", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // -----------------------------

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    //______
    if (token) {
      stateChange();
      dispatch(errorReset());
      dispatch(fetchVoice());
    }
  }, [dispatch, token]);

  // se non è presente il token allora il cliente  non è registrato oppure non è loggato
  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  // resetta errori
  const alterShow = () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----
    dispatch(errorReset());
  };

  const renderVoiceCassa = () => {
    return arrVociCassa.map((voce, index) => {
      return <VoiceCardSetting key={index} voice={voce} />;
    });
  };

  const handleAddVociCassa = async () => {
    const { value: formValues3 } = await Swal.fire({
      title: "Nuova Voce",
      html:
        '<p class="swal2-header">Inserisci il nome della voce</p>' +
        '<input id="swal-input1" class="swal2-input" type="text" placeholder="es. Distributore Presi ">' +
        `<p class="swal2-header"> se è quantificabile insiersci la percentuale di guadagno relaitava a questa voce altrimenti inserisci '0'</p>` +
        '<input id="swal-input3" class="swal2-input" type="number" placeholder="es. 10 or 0 ">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value.trim(), document.getElementById("swal-input3").value];
      },
    });

    if (formValues3) {
      if (!formValues3[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito la percentuale di guadagno, se non è quantificabile inserisci 0",
        });
        return;
      }

      if (formValues3[0] && formValues3[1]) {
        dispatch(voiceAdd(formValues3[0].replaceAll(" ", "-").toLowerCase(), formValues3[0], Number(formValues3[1])));
      }
    }
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="voice-setting">
        <div className="voice-header">
          <Box>
            <Typography variant="h5" className="voice-title">
              Impostazioni Voci Cassa
            </Typography>
            <Typography variant="body2" className="voice-description">
              Gestisci le voci cassa e le percentuali di guadagno
            </Typography>
          </Box>
          <Tooltip title="Aggiungi nuova voce" arrow>
            <IconButton
              onClick={handleAddVociCassa}
              sx={{
                backgroundColor: "var(--accent-color)",
                color: "white",
                "&:hover": {
                  backgroundColor: "var(--accent-color-hover)",
                },
                boxShadow: "0 2px 5px rgba(253, 140, 34, 0.3)",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 10px rgba(253, 140, 34, 0.4)",
                },
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>

        <div className="voice-body">
          {renderVoiceCassa()}
          <div className="voice-card-container-add" onClick={handleAddVociCassa}>
            <AddIcon sx={{ fontSize: 60, color: "var(--accent-color)", mb: 2 }} />
            <Typography variant="subtitle1" sx={{ color: "var(--primary-color)", fontWeight: 500 }}>
              Aggiungi Nuova Voce
            </Typography>
          </div>
        </div>

        {error ? (playError(), notifyError(), alterShow()) : null}
        {success ? (notifySuccess(), alterShow()) : null}
        <ToastContainer
          position="top-letf"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default VoiceSetting;
