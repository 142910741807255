import React from "react";
import Faq from "react-faq-component";
import { useSelector } from "react-redux";
import { data } from "../components/DataHelp";
import Navbar from "../components/Navbar";
import "../styles/Help.css";
import { Container, Paper, Typography, Box } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function Help() {
  const userToken = useSelector((state) => state.authReducer.token);

  const styles = {
    bgColor: "white",
    titleTextColor: "var(--primary-color)",
    rowTitleColor: "var(--secodary-color)",
    rowTitleTextSize: "16px",
    rowContentColor: "rgba(0, 0, 0, 0.7)",
    rowContentTextSize: "14px",
    rowContentPaddingTop: "16px",
    rowContentPaddingBottom: "16px",
    rowContentPaddingLeft: "24px",
    rowContentPaddingRight: "24px",
    arrowColor: "var(--accent-color)",
  };

  const config = {
    animate: true,
    tabFocus: true,
    expandIcon: "+",
    collapseIcon: "-",
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <Container className="help-container">
        <Paper elevation={0} className="help-paper">
          <Box className="help-header">
            <HelpOutlineIcon className="help-icon" />
            <Box>
              <Typography variant="h4" className="help-title">
                Assistenza
              </Typography>
              <Typography variant="body2" className="help-subtitle">
                Trova risposte alle domande più frequenti
              </Typography>
            </Box>
          </Box>

          <Box className="faq-container">
            <Faq data={data} styles={styles} config={config} />
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default Help;
