import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { CeckConnection } from "../functions/ConnectionStatius";
import Swal from "sweetalert2";
import "../styles/Report.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { errorReset } from "../store/actions/handleHome";
import { fetchReport } from "../store/actions/handelReport";
import Spinner from "../components/Spinner";
import { StyleReportTotColorObject } from "../functions/StyleReportTotColorObject";
import { stateChange } from "../functions/stateChange";
import * as VscIcons from "react-icons/vsc";
import * as BiIcons from "react-icons/bi";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Chart from "react-apexcharts";
import "../styles/Price.scss";

// Material-UI imports
import { Container, Paper, Typography, Box, Grid, Button, IconButton, Tooltip, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PaymentsIcon from "@mui/icons-material/Payments";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ListAltIcon from "@mui/icons-material/ListAlt";

import CardSingleStats from "../components/reportComponent/CardSingleStats";
import CardSingleStatsChart from "../components/reportComponent/CardSingleStatsChart";

function Report() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userToken = useSelector((state) => state.authReducer.token);

  const {
    loading,
    arrayReport,
    arrVociCassa,
    arrTotVociCassa,
    totNetto,
    dataInizioStatistiche,
    dataFineStatistiche,
    option,
    option_spese,
  } = useSelector((state) => state.reportReducer);

  const [inputDal, setInputDal] = useState(new Date());
  const [inputAl, setInputAl] = useState(new Date());
  const styleTotNetto = StyleReportTotColorObject(totNetto);

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    if (token) {
      stateChange();
      dispatch(errorReset());
      dispatch(fetchReport(dateFormater(inputDal), dateFormater(inputAl)));
    }
    // eslint-disable-next-line
  }, [dispatch, token]);

  // se non è presente il token allora il cliente  non è registrato oppure non è loggato
  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  const renderFooterReport = () => {
    const listDate = arrayReport.map((item) => item.data);
    return arrVociCassa
      .filter((x) => x.alias !== "aggio-giornaliero" && x.alias !== "spese")
      .map((voce, index) => {
        const arrVoce = arrayReport.map((item) => {
          // Verifica se esistono dati per questa voce
          const filteredValues = item.arrayDay.arrayClean.filter((x) => x.alias === voce.alias);
          return filteredValues.length > 0 ? filteredValues : [{ valore: 0 }];
        });

        const options = {
          series: [
            {
              name: "Giorno",
              // Assicurati che ogni valore sia un numero valido, altrimenti usa 0
              data: arrVoce.map((item) => {
                const value = item[0]?.valore;
                return value !== undefined && !isNaN(Number(value)) ? Number(value) : 0;
              }),
            },
          ],
          options: {
            chart: {
              height: 350,
              type: "line",
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: listDate,
              labels: {
                show: false,
              },
            },
          },
        };

        return <CardSingleStatsChart key={index} name={voce.nome} option={options} percent={voce.percentualeQuadagno} />;
      });
  };

  const handleCerca = (date1, date2) => {
    dispatch(fetchReport(dateFormater(date2), dateFormater(date1)));
  };

  const dateFormater = (date) => {
    return date.toLocaleDateString().replaceAll("/", "-");
  };

  const totGuadagnoLordo = () => {
    if (arrTotVociCassa.length === 0) return 0;
    const arrTot = arrTotVociCassa.map((item) => Number(item.totVoce));
    const tot = arrTot.reduce((a, b) => a + b, 0);
    return tot.toFixed(2);
  };

  // Funzione per ottenere il totale delle spese
  const getTotaleSpese = () => {
    if (arrTotVociCassa.length === 0) return "0.00";
    // Cerca la voce "spese" nell'array
    const spese = arrTotVociCassa.find((item) => item.alias === "spese");
    return spese ? Number(spese.totVoce).toFixed(2) : "0.00";
  };

  const handleDateSelect = async (date, type) => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    if (type) {
      setInputAl(date);
    } else {
      setInputDal(date);
    }
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="report">
        {loading ? (
          <Spinner />
        ) : (
          <Container maxWidth={false} disableGutters className="report-container-modern">
            {/* Header with title */}
            <Box className="report-header">
              <Box className="report-title-section">
                <AssessmentIcon className="report-icon" />
                <Typography variant="h4" component="h1" className="report-title">
                  Report Finanziario
                </Typography>
              </Box>

              <Paper elevation={3} className="report-controls">
                <Grid container spacing={2} alignItems="center">
                  {/* Date picker section */}
                  <Grid item xs={12} md={8}>
                    <Box className="date-picker-container">
                      <Box className="date-picker-item">
                        <Typography variant="subtitle1">Dal</Typography>
                        <Box className="date-input-wrapper">
                          <DatePicker
                            locale="it"
                            selected={inputDal}
                            value={inputDal}
                            onChange={(date) => handleDateSelect(date, false)}
                            dateFormat="dd-MM-yyyy"
                            className="date-input-modern"
                            maxDate={inputAl}
                            withPortal
                            portalId="root-portal"
                          />
                          <CalendarMonthIcon className="date-icon" />
                        </Box>
                      </Box>

                      <Box className="date-picker-item">
                        <Typography variant="subtitle1">Al</Typography>
                        <Box className="date-input-wrapper">
                          <DatePicker
                            locale="it"
                            selected={inputAl}
                            value={inputAl}
                            onChange={(date) => handleDateSelect(date, true)}
                            dateFormat="dd-MM-yyyy"
                            className="date-input-modern"
                            maxDate={new Date()}
                            withPortal
                            portalId="root-portal"
                          />
                          <CalendarMonthIcon className="date-icon" />
                        </Box>
                      </Box>

                      <Button
                        variant="contained"
                        startIcon={<SearchIcon />}
                        className="search-button"
                        onClick={() => handleCerca(inputDal, inputAl)}
                      >
                        Cerca
                      </Button>
                    </Box>
                  </Grid>

                  {/* Export button */}
                  <Grid item xs={12} md={4}>
                    <Box className="export-button-container">
                      <Tooltip title="Esporta report in PDF">
                        <Link
                          className="link-pdf-modern"
                          to={{
                            pathname: `/report/pdf`,
                            state: {
                              arrayReport,
                              arrVociCassa,
                              arrTotVociCassa,
                              dataInizioStatistiche,
                              dataFineStatistiche,
                            },
                          }}
                        >
                          <Button variant="contained" startIcon={<PictureAsPdfIcon />} className="export-button">
                            Esporta PDF
                          </Button>
                        </Link>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>

              {/* Summary section */}
              <Paper elevation={3} className="summary-section">
                <Grid container spacing={3}>
                  {/* Periodo */}
                  <Grid item xs={12} md={6} lg={3}>
                    <Box className="simple-summary-card">
                      <Box className="simple-summary-icon-container">
                        <DateRangeIcon className="simple-summary-icon" />
                      </Box>
                      <Box className="simple-summary-content">
                        <Typography variant="subtitle1" className="simple-summary-title">
                          Periodo
                        </Typography>
                        <Typography variant="body1" className="simple-summary-value">
                          {dataInizioStatistiche} - {dataFineStatistiche}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Guadagno Lordo */}
                  <Grid item xs={12} md={6} lg={3}>
                    <Box className="simple-summary-card">
                      <Box className="simple-summary-icon-container">
                        <AccountBalanceWalletIcon className="simple-summary-icon" />
                      </Box>
                      <Box className="simple-summary-content">
                        <Typography variant="subtitle1" className="simple-summary-title">
                          Guadagno Lordo
                        </Typography>
                        <Typography variant="h6" className="simple-summary-value amount">
                          {totGuadagnoLordo()} €
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Totale Spese */}
                  <Grid item xs={12} md={6} lg={3}>
                    <Box className="simple-summary-card">
                      <Box className="simple-summary-icon-container">
                        <MoneyOffIcon className="simple-summary-icon" />
                      </Box>
                      <Box className="simple-summary-content">
                        <Typography variant="subtitle1" className="simple-summary-title">
                          Totale Spese
                        </Typography>
                        <Typography variant="h6" className="simple-summary-value expenses">
                          {getTotaleSpese()} €
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Guadagno Netto */}
                  <Grid item xs={12} md={6} lg={3}>
                    <Box className="simple-summary-card">
                      <Box className="simple-summary-icon-container">
                        <PaymentsIcon className="simple-summary-icon" />
                      </Box>
                      <Box className="simple-summary-content">
                        <Typography variant="subtitle1" className="simple-summary-title">
                          Guadagno Netto
                        </Typography>
                        <Typography variant="h6" className="simple-summary-value" style={styleTotNetto}>
                          {totNetto} €
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            {/* Voci Cassa section */}
            <Paper elevation={3} className="voci-cassa-section">
              <Box className="section-header">
                <ListAltIcon className="section-icon" />
                <Typography variant="h5" className="section-title">
                  Voci Cassa
                </Typography>
              </Box>
              <Box className="voci-cassa-inner-container">
                <Box className="voci-cassa-container">
                  {arrVociCassa.length > 0 ? (
                    arrVociCassa
                      .filter((voce) => voce.alias !== "aggio-giornaliero")
                      .map((voce, index) => {
                        // Calcola il valore totale per questa voce
                        const totaleVoce = arrTotVociCassa.find((item) => item.alias === voce.alias);
                        const valore = totaleVoce ? Number(totaleVoce.totVoce).toFixed(2) : "0.00";

                        // Determina il colore in base all'alias
                        const isSpesa = voce.alias === "spese";

                        return (
                          <Box key={index} className="voce-cassa-item">
                            <Box
                              className="voce-cassa-icon-container"
                              style={{ backgroundColor: isSpesa ? "#ffebee" : "#e8f0fe" }}
                            >
                              {isSpesa ? (
                                <MoneyOffIcon className="voce-cassa-icon" style={{ color: "#e53935" }} />
                              ) : (
                                <PaymentsIcon className="voce-cassa-icon" style={{ color: "var(--accent-color)" }} />
                              )}
                            </Box>
                            <Box className="voce-cassa-content">
                              <Typography variant="subtitle1" className="voce-cassa-title">
                                {voce.nome}
                              </Typography>
                              <Typography
                                variant="h6"
                                className="voce-cassa-value"
                                style={{ color: isSpesa ? "#e53935" : "var(--accent-color)" }}
                              >
                                {valore} €
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })
                  ) : (
                    <Typography variant="body1" style={{ padding: "16px", textAlign: "center" }}>
                      Nessuna voce cassa disponibile
                    </Typography>
                  )}
                </Box>
              </Box>
            </Paper>

            {/* Charts section */}
            <Paper elevation={3} className="charts-section">
              <Box className="section-header">
                <TrendingUpIcon className="section-icon" />
                <Typography variant="h5" className="section-title">
                  Andamento Statistiche
                </Typography>
              </Box>
              <Box className="charts-container">{renderFooterReport()}</Box>
            </Paper>
          </Container>
        )}
      </div>

      {/* Portale per i date picker */}
      <div id="root-portal" />
    </>
  );
}

export default Report;
