import React, { useState } from "react";
import "../styles/ContactForm.css";
import axios from "axios";
import Swal from "sweetalert2";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import { TextField, Button, Typography, Box, Container, Paper, Grid, InputAdornment } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import MessageIcon from "@mui/icons-material/Message";
import SendIcon from "@mui/icons-material/Send";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

function ContactForm() {
  const userToken = useSelector((state) => state.authReducer.token);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const submitForm = async (e) => {
    e.preventDefault();
    if (formData.name.trim() === "") {
      Swal.fire({
        title: "Errore",
        text: "Il campo 'nome' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }
    if (formData.email.trim() === "") {
      Swal.fire("Errore", "Il campo 'email' è vuoto o non valido", "warning");
      return;
    }
    if (formData.message.trim() === "") {
      Swal.fire("Errore", "Il campo 'messaggio' è vuoto o non valido", "warning");
      return;
    }
    try {
      await axios.post("https://submit-form.com/vkScKeBC", formData);
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      Swal.fire("Success", "Richiesta inviata con successo", "success");
    } catch (error) {
      Swal.fire("Errore", error.message, "error");
      console.log(error.message);
    }
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <Container className="contact-form-container">
        <Paper elevation={0} className="contact-form-paper">
          <Box className="contact-header">
            <MailOutlineIcon className="contact-icon" />
            <Box>
              <Typography variant="h4" className="contact-title">
                Contattaci
              </Typography>
              <Typography variant="body2" className="contact-subtitle">
                Inviaci un messaggio e ti risponderemo al più presto
              </Typography>
            </Box>
          </Box>

          <Box component="form" className="contact-form-box">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Nome"
                  placeholder="Inserisci il tuo nome"
                  variant="outlined"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon sx={{ color: "var(--primary-color)" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "var(--accent-color)",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Inserisci la tua email"
                  variant="outlined"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon sx={{ color: "var(--primary-color)" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "var(--accent-color)",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="message"
                  name="message"
                  label="Messaggio"
                  placeholder="Scrivi il tuo messaggio"
                  variant="outlined"
                  multiline
                  rows={6}
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MessageIcon sx={{ color: "var(--primary-color)" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "var(--accent-color)",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                <Button
                  variant="contained"
                  size="large"
                  endIcon={<SendIcon />}
                  onClick={submitForm}
                  className="submit-button"
                  sx={{
                    backgroundColor: "var(--accent-color)",
                    "&:hover": {
                      backgroundColor: "var(--accent-color-hover)",
                    },
                    borderRadius: "8px",
                    boxShadow: "0 2px 5px rgba(253, 140, 34, 0.3)",
                    transition: "all 0.3s ease",
                    padding: "12px 30px",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0 4px 10px rgba(253, 140, 34, 0.4)",
                    },
                  }}
                >
                  Invia Messaggio
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default ContactForm;
