import React from "react";
import { useDispatch } from "react-redux";
import { StyleColorObject } from "../../functions/StyleColorObject";
import Swal from "sweetalert2";
import "./SingleMovCassa.css";
import "../../styles/Glassmorphism.css";
import IcoDelete from "../globalComponent/IcoDelete";
import { Typography, Tooltip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function SingleMovCassa({ mov, day, deleteFunction }) {
  const dispatch = useDispatch();
  const styles = StyleColorObject(mov.importo);

  // Formatta i numeri per renderli più leggibili
  const formatCurrency = (value) => {
    const numValue = parseFloat(value);
    return numValue.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handelDelete = async () => {
    dispatch(deleteFunction(mov.id, mov.importo, day));
  };

  const handelConfirmDelete = () => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare questo elemento ??`,
      text: ` "${mov.infoMov.toUpperCase()}" di "${mov.importo}€" verra eliminato`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(handelDelete);
      }
    });
  };

  return (
    <div className="movement-card">
      {/* Prima riga: Nome movimento e orario */}
      <div className="movement-header">
        <div className="movement-title">
          <Tooltip title={mov.infoMov} placement="top">
            <Typography variant="subtitle2" className="movement-name">
              {mov.infoMov}
            </Typography>
          </Tooltip>
          <div className="movement-time">
            <AccessTimeIcon style={{ fontSize: "14px", color: "#888" }} />
            <Typography className="time-text" variant="caption">
              {mov.time}
            </Typography>
          </div>
        </div>
      </div>

      {/* Seconda riga: Valore e azioni */}
      <div className="movement-content">
        <Typography
          variant="h6"
          className="movement-value"
          style={{
            color: styles.color,
            fontWeight: 600,
          }}
        >
          {formatCurrency(mov.importo)} €
        </Typography>

        <div className="movement-actions">
          <IcoDelete handelConfirmDelete={handelConfirmDelete} />
        </div>
      </div>
    </div>
  );
}

export default SingleMovCassa;
