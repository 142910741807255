import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { voiceDelete, voiceSave } from "../../store/actions/handelVoiceSetting";
import IcoDelete from "../globalComponent/IcoDelete";
import "./VoiceCardSetting.css";
import { TextField, Typography, Button, Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

function VoiceCardSetting({ voice }) {
  const dispatch = useDispatch();
  const [nome, setNome] = useState(voice.nome);
  const [percentualeQuadagno, setPercentualeQuadagno] = useState(voice.percentualeQuadagno);

  useEffect(() => {
    setNome(voice.nome);
    setPercentualeQuadagno(voice.percentualeQuadagno);
  }, [voice.nome, voice.percentualeQuadagno]);

  const handleInputName = (e) => {
    setNome(e.target.value);
    e.preventDefault();
  };

  const handleInputPG = (e) => {
    setPercentualeQuadagno(e.target.value);
    e.preventDefault();
  };

  const handelConfirmDelete = () => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare questa voce?`,
      text: ` "${nome.toUpperCase()}" verrà eliminata dalla lista delle voci`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(voiceDelete(voice));
      }
    });
  };

  const handleSave = () => {
    dispatch(voiceSave(voice, nome, percentualeQuadagno));
  };

  return (
    <div className="voice-card-container">
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
        <IcoDelete handelConfirmDelete={handelConfirmDelete} />
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        value={nome}
        onChange={handleInputName}
        size="small"
        sx={{
          mb: 1,
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            backgroundColor: "#f5f5f5",
            "&.Mui-focused fieldset": {
              borderColor: "var(--accent-color)",
            },
          },
        }}
      />
      <Typography variant="caption" color="text.secondary" sx={{ mb: 2 }}>
        nome della voce
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        type="number"
        value={percentualeQuadagno}
        onChange={handleInputPG}
        size="small"
        sx={{
          mb: 1,
          mt: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            backgroundColor: "#f5f5f5",
            "&.Mui-focused fieldset": {
              borderColor: "var(--accent-color)",
            },
          },
        }}
      />
      <Typography variant="caption" color="text.secondary">
        percentuale di guadagno %
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          sx={{
            backgroundColor: "var(--accent-color)",
            "&:hover": {
              backgroundColor: "var(--accent-color-hover)",
            },
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(253, 140, 34, 0.3)",
            transition: "all 0.3s ease",
            "&:hover": {
              transform: "translateY(-2px)",
              boxShadow: "0 4px 10px rgba(253, 140, 34, 0.4)",
            },
          }}
        >
          SALVA
        </Button>
      </Box>
    </div>
  );
}

export default VoiceCardSetting;
