import { doc, getDoc } from "@firebase/firestore";

import db from "../../Firebase-config";
import { searchVociCassa } from "../../functions/searchVociCassa";
import { searchVociCassaSingleDay } from "../../functions/searchVociCassaSingleDay";
import { getDayDiffDate } from "../../functions/getDayDiffDate";
import { dateFix0 } from "../../functions/dateFix0";
import { DataTranform } from "../../functions/DateTranform";

const FETCH_REPORT_START = "FETCH_REPORT_START";
const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
const FETCH_REPORT_FAIL = "FETCH_REPORT_FAIL";

export const fetchReport = (date, date2) => {
  return async (dispatch) => {
    dispatch(fetchReportStart());
    try {
      let dataTemp = await dateFix0(date);
      let days = 86400000; //number of milliseconds in a day

      let dataInizioStatistiche = null;
      let dataFineStatistiche = null;

      const arrayReport = [];
      const arrTotVoci = [];

      // riferimento univo del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello specifici

      // first fetch the VociCassa for univoc alias and then search the specific value for day to day
      const arrVociCassa = await searchVociCassa();
      const day = await getDayDiffDate(date, date2);

      //----------------------------------------------------------------
      for (let i = 0; i <= day; i++) {
        // sotttraggo la data
        let dataT = new Date(dataTemp - i * days);

        if (i === 1) {
          dataInizioStatistiche = dataT.toLocaleDateString().replaceAll("/", "-");
        }
        if (i === day - 1) {
          dataFineStatistiche = dataT.toLocaleDateString().replaceAll("/", "-");
        }
        //azzero le varibaili del singolo fetch

        const addReDay = doc(addRefCalendario, "giorni", DataTranform(dataT)); // rif caledario giorno specifo

        const data = await getDoc(addReDay);
        const dExist = data.exists();
        if (dExist === false) {
          // il giorno non esiste
        } else {
          let arraySingleDay = await searchVociCassaSingleDay(DataTranform(dataT));
          const arrayClean = [];
          // inserisco nella prima riga le spese;
          const totSpese = data._document.data.value.mapValue.fields.totaleSpese.doubleValue
            ? data._document.data.value.mapValue.fields.totaleSpese.doubleValue
            : data._document.data.value.mapValue.fields.totaleSpese.integerValue;

          // fetch aggio giornaliero
          let accGuadagno = 0;
          arraySingleDay.forEach((item) => {
            if (item.percentualeQuadagno !== "0") {
              accGuadagno = accGuadagno + Number(((Number(item.percentualeQuadagno) * Number(item.valore)) / 100).toFixed(2));
            }
          });

          let aggio = accGuadagno.toFixed(2);

          arrayClean.push({
            alias: "spese",
            percentualeQuadagno: 0,
            nome: "Spese",
            valore: totSpese,
          });
          arrayClean.push({
            alias: "aggio-giornaliero",
            percentualeQuadagno: 100,
            nome: "Aggio Giornaliero",
            valore: aggio,
          });
          // fine sepese
          arrTotVoci.push({ alias: "spese", valore: totSpese });
          arrTotVoci.push({ alias: "aggio-giornaliero", valore: aggio });

          const arrAlias = arraySingleDay.map((v) => v.alias); // mappo l'array per avere solo i valori degli alias
          // rimuovo le voci che non sono in VociCassa
          // e aggiungo voce a 0 per i giorno che ne hanno meno
          arrVociCassa.forEach((element) => {
            arraySingleDay.every((voce) => {
              if (element.alias === voce.alias) {
                arrayClean.push(voce);
                arrTotVoci.push({ alias: voce.alias, valore: voce.valore });
              } else {
                if (!arrAlias.includes(element.alias)) {
                  arrayClean.push({
                    alias: voce.alias,
                    percentualeQuadagno: voce.percentualeQuadagno,
                    nome: voce.nome,
                    valore: 0,
                  });
                  return false;
                }
              }
              return true;
            });
          });

          arrayReport.push({
            data: dataT,
            arrayDay: { arrayClean },
          }); // pusho tutto in un array che andrò ad elaborare
        }
      }

      // end
      // aggiungo la voce spese alla fine cosi da non far impallare
      arrVociCassa.unshift({
        nome: "Aggio Giornaliero",
        alias: "aggio-giornaliero",
        percentualeQuadagno: 100,
      });
      arrVociCassa.unshift({ nome: "Spese", alias: "spese", percentualeQuadagno: 0 });
      // estraggo i totali di ogni singola voce nel arco temporale scelto
      var lodash = require("lodash");
      const arrTotVociCassa = [];
      arrVociCassa.forEach((eV) => {
        const arrFiler = arrTotVoci.filter((fE) => fE.alias === eV.alias);
        const arrTot = arrFiler.map((x) => Number(x.valore));

        const totVoce = lodash.sum(arrTot);
        arrTotVociCassa.push({
          totVoce: totVoce.toFixed(2),
          perQ: eV.percentualeQuadagno,
          alias: eV.alias,
        });
      });

      const totNetto = Number(arrTotVociCassa[1].totVoce) + Number(arrTotVociCassa[0].totVoce);

      const option = {
        series: arrTotVociCassa
          .filter((x) => x.alias !== "aggio-giornaliero" && x.alias !== "spese")
          .map((item) => Number(item.totVoce)),
        options: {
          chart: {
            width: 500,
            type: "pie",
          },
          labels: arrVociCassa.filter((x) => x.alias !== "aggio-giornaliero" && x.alias !== "spese").map((item) => item.nome),
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };

      const option_spese = {
        series: [
          {
            name: "Spese",
            data: arrayReport.map((item) => item.arrayDay.arrayClean[0].valore),
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: arrayReport.map((item) => item.data),
            labels: {
              show: false,
            },
          },
          yaxis: {
            title: {
              text: "Spese ",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " €";
              },
            },
          },
        },
      };

      dispatch(
        fetchReportSuccess(
          arrayReport,
          arrVociCassa,
          arrTotVociCassa,
          dataInizioStatistiche,
          dataFineStatistiche,
          totNetto,
          option,
          option_spese
        )
      );
    } catch (error) {
      dispatch(fetchReportFail(error));
    }
  };
};

export const fetchReportStart = () => {
  return {
    type: FETCH_REPORT_START,
  };
};

export const fetchReportSuccess = (
  arrayReport,
  arrVociCassa,
  arrTotVociCassa,
  dataInizioStatistiche,
  dataFineStatistiche,
  totNetto,
  option,
  option_spese
) => {
  return {
    type: FETCH_REPORT_SUCCESS,
    arrayReport,
    arrVociCassa,
    arrTotVociCassa,
    dataInizioStatistiche,
    dataFineStatistiche,
    totNetto,
    option,
    option_spese,
  };
};

export const fetchReportFail = (error) => {
  return {
    type: FETCH_REPORT_FAIL,
    error: error,
  };
};

export { FETCH_REPORT_START, FETCH_REPORT_SUCCESS, FETCH_REPORT_FAIL };
