import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { fetchData } from "../../store/actions/handelData";
import { useEffect } from "react";
import "fix-date";
import { DataTranform } from "../../functions/DateTranform";
import { CeckConnection } from "../../functions/ConnectionStatius";
import Swal from "sweetalert2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Tooltip } from "@mui/material";

function DataPikers({ fetchFunctions }) {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [daySelected, setDaySelected] = useState(startDate.toLocaleDateString().replaceAll("/", "-"));

  useEffect(() => {
    const date = daySelected.split("-");
    const newDate = DataTranform(new Date(date[2], date[1] - 1, date[0]));
    dispatch(fetchData(newDate));
    dispatch(fetchFunctions(newDate, 30));
  }, [dispatch, fetchFunctions, daySelected]);

  // gestisce la parte in cui è stata gia selezionata la data
  const handleDateSelect = async (date) => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----
    setStartDate(date);
    const day = await date.toLocaleDateString().replaceAll("/", "-");
    dispatch(fetchFunctions(day, 30));
    setDaySelected(day);
    dispatch(fetchData(DataTranform(date)));
    setIsOpen(false);
  };

  // mostra il caledario da cui scegliere la data
  const rederDatePikers = (e) => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <div className="data-pikers">
      {isOpen ? (
        <DatePicker
          className="data-pikers-date"
          locale="it"
          selected={startDate}
          value={startDate}
          onChange={(date) => handleDateSelect(date)}
          dateFormat="dd-MM-yyyy"
          calendarClassName="data-calendar"
          inline
        />
      ) : (
        <Tooltip title="Seleziona data" placement="top" arrow>
          <button className="btn-date" onClick={rederDatePikers}>
            {startDate.toLocaleDateString()}
            <CalendarMonthIcon className="icon-calendar" />
          </button>
        </Tooltip>
      )}
    </div>
  );
}

export default DataPikers;
