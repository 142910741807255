import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { CeckConnection } from "../functions/ConnectionStatius";
import Swal from "sweetalert2";
import "../styles/ListaCrediti.css";
import { useDispatch } from "react-redux";
import { addNameRubrica, fetchRubrica } from "../store/actions/handelListaCrediti";
import { useSelector } from "react-redux";
import { errorReset } from "../store/actions/handleHome";

import * as ImIcons from "react-icons/im";
import * as BsIcons from "react-icons/bs";
import CreditCard from "../components/listaCreditiComponent/CreditCard";
import { StyleColorObject } from "../functions/StyleColorObject";
import HeadTot from "../components/globalComponent/HeadTot";
import { stateChange } from "../functions/stateChange";
import Navbar from "../components/Navbar";
import soudError from "../sound/error.wav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Price.scss";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function ListaCrediti() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userToken = useSelector((state) => state.authReducer.token);
  const [input, setInput] = useState("");
  const [sort, setSort] = useState("surname");
  const [surname, setSurname] = useState(true);
  const [name, setName] = useState(true);
  const [importo, setImporto] = useState(true);

  let arrayRubrica = useSelector((state) => state.listaCreditiReducer.arrayRubrica);

  const { totCrediti, storicoCrediti, error, message } = useSelector((state) => state.listaCreditiReducer);

  const style = StyleColorObject(Number(totCrediti));

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    //______
    if (token) {
      stateChange();
      dispatch(errorReset());
      dispatch(fetchRubrica());
    }
  }, [dispatch, token, sort]);

  // se non è presente il token allora il cliente  non è registrato oppure non è loggato
  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  // tost message errore
  const notifyError = () =>
    toast.error(message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  // audio errore
  const audio = new Audio(soudError);
  audio.loop = false;
  const playError = () => {
    audio.loop = false;
    audio.play();
  };

  // resetta errori
  const alterShow = () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----
    dispatch(errorReset());
  };

  const handleAddNameRubrica = async () => {
    const { value: formValues } = await Swal.fire({
      title: "NUOVO NOME",
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="cognome">' +
        '<input id="swal-input2" class="swal2-input" placeholder="nome">' +
        '<input id="swal-input3" class="swal2-input" type=number placeholder="0">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").value,
        ];
      },
    });
    if (formValues) {
      if (!formValues[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del COGNOME è vuoto",
        });
        return;
      }
      if (!formValues[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del NOME è vuoto",
        });
        return;
      }
      if (!formValues[2]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del CREDITO è vuoto",
        });
        return;
      }
      dispatch(addNameRubrica(formValues[0].trim(), formValues[1].trim(), formValues[2]));
    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  const renderStoricoCrediti = () => {
    if (storicoCrediti.length === 0) {
      return null;
    } else {
      return storicoCrediti.reverse().map((s, index) => {
        const style = StyleColorObject(s.importo);
        return (
          <div className="storico-render" key={index}>
            <div className="storico-info">
              <Typography variant="body2" className="storico-data">
                {s.data}
              </Typography>
              <Typography variant="body2" className="storico-ora">
                {s.ora}
              </Typography>
            </div>
            <Typography
              variant="body1"
              className={Number(s.importo) > 0 ? "positive-amount" : "negative-amount"}
              sx={{ fontWeight: 500 }}
            >
              {s.importo} €
            </Typography>
          </div>
        );
      });
    }
  };

  const renderRubrica = () => {
    if (sort === "surname") {
      arrayRubrica = arrayRubrica.sort((a, b) => a.cognome.localeCompare(b.cognome));
      if (!surname) {
        arrayRubrica = arrayRubrica.sort((b, a) => a.cognome.localeCompare(b.cognome));
      }
    }
    if (sort === "name") {
      arrayRubrica = arrayRubrica.sort((a, b) => a.nome.localeCompare(b.nome));
      if (!name) {
        arrayRubrica = arrayRubrica.sort((b, a) => a.nome.localeCompare(b.nome));
      }
    }
    if (sort === "import") {
      arrayRubrica = arrayRubrica.sort((a, b) =>
        String(a.credititoTotale).localeCompare(String(b.credititoTotale), undefined, {
          numeric: true,
        })
      );
      if (!importo) {
        arrayRubrica = arrayRubrica.sort((b, a) =>
          String(a.credititoTotale).localeCompare(String(b.credititoTotale), undefined, {
            numeric: true,
          })
        );
      }
    }
    return (
      arrayRubrica
        // eslint-disable-next-line array-callback-return
        .filter((f) => {
          if (input === "") {
            return f;
          } else if (
            f.nome.toLowerCase().includes(input.toLowerCase()) ||
            f.cognome.toLowerCase().includes(input.toLowerCase()) ||
            String(f.credititoTotale).toLowerCase().includes(input.toLowerCase())
          ) {
            return f;
          }
        })
        .map((item, index) => {
          return (
            <>
              <CreditCard item={item} index={index} />
            </>
          );
        })
    );
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="container-lista-crediti">
        <div className="lista-crediti">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "12px",
              padding: "16px 20px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
              marginBottom: "20px",
              transition: "all 0.3s ease",
              "&:hover": {
                boxShadow: "0 6px 16px rgba(0, 0, 0, 0.08)",
                transform: "translateY(-2px)",
              },
            }}
          >
            <GroupAddIcon sx={{ color: "var(--accent-color)", fontSize: "32px", marginRight: "16px" }} />
            <Box>
              <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "14px" }}>
                Totale Crediti
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: Number(totCrediti) < 0 ? "var(--color-delete-ico)" : "var(--color-save-ico)",
                  fontWeight: 600,
                }}
              >
                {totCrediti.toFixed(2)} €
              </Typography>
            </Box>
          </Box>

          <div className="lista-credit-head">
            <TextField
              type="text"
              className="input-stampa"
              placeholder="Inserisci il criterio di ricerca"
              onChange={handleInput}
              value={input}
              variant="standard"
              fullWidth
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.54)", marginLeft: "10px" }} />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton className="btn-add-credito" onClick={handleAddNameRubrica} aria-label="Aggiungi credito">
              <GroupAddIcon />
            </IconButton>
          </div>

          <div className="t-fm">
            <div className="local-bootstrap">
              <TableContainer component={Paper} className="credits-table">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                            Cognome
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSort("surname");
                              setSurname(!surname);
                            }}
                            sx={{ ml: 0.5 }}
                          >
                            {sort === "surname" && surname ? (
                              <ArrowUpwardIcon fontSize="small" sx={{ color: "var(--accent-color)" }} />
                            ) : (
                              <ArrowDownwardIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                            )}
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                            Nome
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSort("name");
                              setName(!name);
                            }}
                            sx={{ ml: 0.5 }}
                          >
                            {sort === "name" && name ? (
                              <ArrowUpwardIcon fontSize="small" sx={{ color: "var(--accent-color)" }} />
                            ) : (
                              <ArrowDownwardIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                            )}
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                            Importo
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSort("import");
                              setImporto(!importo);
                            }}
                            sx={{ ml: 0.5 }}
                          >
                            {sort === "import" && importo ? (
                              <ArrowUpwardIcon fontSize="small" sx={{ color: "var(--accent-color)" }} />
                            ) : (
                              <ArrowDownwardIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                            )}
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                          Azioni
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderRubrica()}</TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        <div className="storico-crediti-container">
          <div className="storico-crediti-head">
            <Typography variant="subtitle1" sx={{ fontWeight: 500, letterSpacing: "0.5px" }}>
              STORICO CREDITI
            </Typography>
          </div>

          <div className="storico-crediti-body">
            {storicoCrediti.length === 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <Typography variant="body2" color="text.secondary">
                  Nessun movimento storico disponibile
                </Typography>
              </Box>
            ) : (
              renderStoricoCrediti()
            )}
          </div>
        </div>
        {error ? (playError(), notifyError(), alterShow()) : null}
        <ToastContainer
          position="top-letf"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default ListaCrediti;
