import React from "react";
import { useDispatch } from "react-redux";
import { deleteNameRubrica, updateStoricoCrediti } from "../../store/actions/handelListaCrediti";
import "./CreditCard.css";
import { StyleColorObject } from "../../functions/StyleColorObject";
import IcoDelete from "../globalComponent/IcoDelete";
import Swal from "sweetalert2";
import { TableCell, TableRow, Tooltip, Typography, Box } from "@mui/material";

function CreditCard({ item, index }) {
  const dispatch = useDispatch();
  const styles = StyleColorObject(Number(item.credititoTotale));

  const showStoricoCrediti = () => {
    dispatch(updateStoricoCrediti(item.storicoCrediti));
  };

  const handelConfirmDelete = () => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare questo nome?`,
      text: `${item.cognome.toUpperCase()} ${item.nome.toUpperCase()} verrà eliminato`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteNameRubrica(item.alias));
      }
    });
  };

  return (
    <Tooltip title="Clicca per vedere lo storico dei movimenti" arrow placement="top">
      <TableRow
        onClick={showStoricoCrediti}
        sx={{
          cursor: "pointer",
          transition: "all 0.3s ease",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            transform: "translateY(-2px)",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
          },
        }}
      >
        <TableCell>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {item.cognome.toUpperCase()}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{item.nome.toUpperCase()}</Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            className={Number(item.credititoTotale) > 0 ? "positive-amount" : "negative-amount"}
            sx={{ fontWeight: 500 }}
          >
            {item.credititoTotale} €
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IcoDelete handelConfirmDelete={handelConfirmDelete} />
          </Box>
        </TableCell>
      </TableRow>
    </Tooltip>
  );
}

export default CreditCard;
