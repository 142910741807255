import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import { DataTranform } from "../../functions/DateTranform";
// Create styles

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#f8f9fa",
    color: "#333",
    margin: "100 100 100 100",
    fontFamily: "Helvetica",
  },
  header: {
    marginBottom: 30,
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 8,
  },
  title: {
    fontSize: 50,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 15,
    color: "#2b3041",
  },
  subtitle: {
    fontSize: 30,
    textAlign: "center",
    marginBottom: 30,
    color: "#666",
  },
  tableContainer: {
    marginTop: 30,
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#2b3041",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: 10,
  },
  tableHeaderCell: {
    color: "#fff",
    fontSize: 30,
    fontWeight: "bold",
    padding: 15,
    textAlign: "center",
    flex: 1,
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
    backgroundColor: "#fff",
    padding: 10,
  },
  tableRowAlt: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
    backgroundColor: "#f5f5f5",
    padding: 10,
  },
  tableFooter: {
    flexDirection: "row",
    backgroundColor: "#f0f0f0",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 10,
    borderTopWidth: 2,
    borderTopColor: "#2b3041",
  },
  tableCell: {
    fontSize: 30,
    padding: 15,
    textAlign: "center",
    flex: 1,
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellPositive: {
    fontSize: 30,
    padding: 15,
    textAlign: "center",
    color: "#4caf50",
    fontWeight: "bold",
    flex: 1,
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e8f5e9",
  },
  tableCellNegative: {
    fontSize: 30,
    padding: 15,
    textAlign: "center",
    color: "#f44336",
    fontWeight: "bold",
    flex: 1,
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffebee",
  },
  tableCellTotal: {
    fontSize: 35,
    padding: 15,
    textAlign: "center",
    fontWeight: "bold",
    flex: 1,
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellTotalPositive: {
    fontSize: 35,
    padding: 15,
    textAlign: "center",
    color: "#4caf50",
    fontWeight: "bold",
    flex: 1,
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e8f5e9",
  },
  tableCellTotalNegative: {
    fontSize: 35,
    padding: 15,
    textAlign: "center",
    color: "#f44336",
    fontWeight: "bold",
    flex: 1,
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffebee",
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  footer: {
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 20,
    color: "#666",
  },
});

// Create Document Component
function CreatePdf({ arrayReport, arrVociCassa, arrTotVociCassa, dataInizioStatistiche, dataFineStatistiche }) {
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="4A0" style={styles.page} orientation={"landscape"}>
          {/* Header */}
          <View style={styles.header}>
            <Text style={styles.title}>Report Finanziario</Text>
            <Text style={styles.subtitle}>
              Periodo: {dataInizioStatistiche} - {dataFineStatistiche}
            </Text>
          </View>

          {/* Table */}
          <View style={styles.tableContainer}>
            {/* Table Header */}
            <View style={styles.tableHeader}>
              <Text style={styles.tableHeaderCell}>DATA</Text>
              {arrVociCassa.map((voce, i) => (
                <Text key={i + "id"} style={styles.tableHeaderCell}>
                  {voce.nome}
                </Text>
              ))}
            </View>

            {/* Table Body */}
            {arrayReport.map((item, i) => (
              <View key={i + "row"} style={i % 2 === 0 ? styles.tableRow : styles.tableRowAlt}>
                <Text style={styles.tableCell}>{DataTranform(item.data)}</Text>
                {item.arrayDay.arrayClean.map((voce, id) => (
                  <Text
                    key={id + "cell"}
                    style={
                      voce.valore > 0 ? styles.tableCellPositive : voce.valore < 0 ? styles.tableCellNegative : styles.tableCell
                    }
                  >
                    {voce.valore}
                  </Text>
                ))}
              </View>
            ))}

            {/* Table Footer */}
            <View style={styles.tableFooter}>
              <Text style={styles.tableCellTotal}>TOTALE</Text>
              {arrTotVociCassa.map((voce, i) => (
                <Text
                  key={i + "total"}
                  style={
                    voce.totVoce > 0
                      ? styles.tableCellTotalPositive
                      : voce.totVoce < 0
                      ? styles.tableCellTotalNegative
                      : styles.tableCellTotal
                  }
                >
                  {voce.totVoce}
                </Text>
              ))}
            </View>
          </View>

          {/* Footer */}
          <Text style={styles.footer}>Generato da FlowMoney - {new Date().toLocaleDateString()}</Text>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default CreatePdf;
