import React from "react";
import { Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import { StyleColorObject } from "../../functions/StyleColorObject";
import "./SingleCreditCard.css";
import "../../styles/Glassmorphism.css";

function SignleCreditCard({ person }) {
  const stylesTCG = StyleColorObject(person.totaleCG);
  const stylesTCT = StyleColorObject(person.credititoTotale);

  // Funzione per formattare i valori monetari
  const formatCurrency = (value) => {
    const numValue = parseFloat(value);
    return numValue.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="credit-card-container">
      {/* Prima riga: Nome e cognome */}
      <div className="credit-card-name">
        <div className="name-icon">
          <PersonIcon fontSize="small" style={{ fontSize: "16px" }} />
        </div>
        <Typography className="name-text" variant="subtitle2">
          {person.cognome} {person.nome}
        </Typography>
      </div>

      {/* Seconda riga: Valori e orario */}
      <div className="credit-card-values">
        {/* Colonna Giorno */}
        <div className="credit-column">
          <Typography className="column-label" variant="caption">
            Giorno
          </Typography>
          <Typography className="column-value" variant="h6" style={{ color: stylesTCG.color }}>
            {formatCurrency(person.totaleCG)} €
          </Typography>
        </div>

        {/* Colonna Totale */}
        <div className="credit-column">
          <Typography className="column-label" variant="caption">
            Totale
          </Typography>
          <Typography className="column-value" variant="h6" style={{ color: stylesTCT.color }}>
            {formatCurrency(person.credititoTotale)} €
          </Typography>
        </div>

        {/* Orario */}
        <div className="credit-time">
          <AccessTimeIcon style={{ fontSize: "14px", color: "#888" }} />
          <Typography className="time-text" variant="caption">
            {person.time || "N/A"}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default SignleCreditCard;
