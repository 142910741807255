import React from "react";
import "./ColumServiceVociCassa.css";
import "../../styles/Glassmorphism.css";
import { Divider, Typography, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

function ColumServiceVociCassa({ value, style, styleAG, addFunction, renderBodyFunction, aggioGiornaliero, datePicker }) {
  // Formatta i valori per renderli più leggibili
  const formatValue = (value) => {
    const numValue = parseFloat(value);
    return numValue.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="voci-cassa">
      {datePicker}
      <div className="totale-cassa-container">
        <div className="glass-card totale-card">
          {/* Header compatto per Totale Cassa */}
          <div className="compact-header">
            <div className="compact-header-icon">
              <AccountBalanceWalletIcon sx={{ fontSize: 20, color: "var(--secondary-color)" }} />
            </div>
            <div className="compact-header-content">
              <Typography variant="subtitle2" className="compact-header-title">
                Totale Cassa
              </Typography>
              <Typography
                variant="h6"
                className="compact-header-value"
                sx={{
                  color:
                    style?.color ||
                    (value < 0 ? "var(--negative-color)" : value > 0 ? "var(--positive-color)" : "var(--secondary-color)"),
                  fontWeight: 600,
                }}
              >
                {formatValue(value)} €
              </Typography>
            </div>
          </div>

          <Divider sx={{ margin: "8px 0", opacity: 0.2 }} />

          {/* Header compatto per Aggio Giornaliero */}
          <div className="compact-header">
            <div className="compact-header-icon">
              <TrendingUpIcon sx={{ fontSize: 20, color: "var(--accent-color)" }} />
            </div>
            <div className="compact-header-content">
              <Typography variant="subtitle2" className="compact-header-title">
                Aggio Giornaliero
              </Typography>
              <Typography
                variant="h6"
                className="compact-header-value"
                sx={{
                  color:
                    styleAG?.color ||
                    (aggioGiornaliero < 0
                      ? "var(--negative-color)"
                      : aggioGiornaliero > 0
                      ? "var(--positive-color)"
                      : "var(--accent-color)"),
                  fontWeight: 600,
                }}
              >
                {formatValue(aggioGiornaliero)} €
              </Typography>
            </div>
          </div>
        </div>

        <div className="glass-card voci-card">
          <div className="header-with-action">
            <Typography variant="subtitle1" className="header-title">
              Voci Cassa
            </Typography>
            <Tooltip title="Aggiungi Voce" placement="top" arrow>
              <IconButton className="header-action-button" onClick={addFunction} size="small" aria-label="Aggiungi voce">
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="body-voci-cassa">{renderBodyFunction()}</div>
        </div>
      </div>
    </div>
  );
}

export default ColumServiceVociCassa;
