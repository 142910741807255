import React from "react";
import "./HeadTot.css";
import "../../styles/Glassmorphism.css";
import { Divider } from "@mui/material";

function HeadTot({ title, value, style, marginLeft, marginTop, marginRight, icon, clickAction }) {
  // Clona l'icona e aggiungi le proprietà onClick e style
  const iconWithClick =
    icon &&
    React.cloneElement(icon, {
      onClick: clickAction,
      style: {
        ...icon.props.style,
        cursor: clickAction && typeof clickAction === "function" ? "pointer" : "default",
        color: "white",
        transition: "all 0.3s ease",
      },
      className: "icon-clickable",
    });

  // Determina la classe di colore in base al valore
  const getValueClass = () => {
    if (!value) return "";
    const numValue = parseFloat(value);
    if (numValue > 0) return "status-positive";
    if (numValue < 0) return "status-negative";
    return "";
  };

  return (
    <div
      className="head-tot"
      style={{
        marginLeft: marginLeft,
        marginTop: marginTop,
        marginRight: marginRight,
        backgroundColor: "var(--primary-color)",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <div className="icon_div">
        {iconWithClick}
        <Divider
          orientation="vertical"
          sx={{
            color: "rgba(255, 255, 255, 0.3)",
            bgcolor: "rgba(255, 255, 255, 0.3)",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            marginLeft: "10px",
          }}
        />
      </div>

      <div className="div_tot">
        <p className={`total-value ${getValueClass()}`} style={{ color: "#fff" }}>
          {value} €
        </p>

        <p className="tot-text">{title}</p>
      </div>
    </div>
  );
}

export default HeadTot;
