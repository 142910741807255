import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton, Tooltip } from "@mui/material";
import "./IcoSave.css";

function IcoSave({ handleSave }) {
  return (
    <Tooltip title="Salva" placement="top" arrow>
      <IconButton className="save-button" onClick={handleSave} size="small" aria-label="Salva">
        <SaveIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

export default IcoSave;
