import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "../styles/Home.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addName,
  addCredito,
  fetchDayTotal,
  errorReset,
  addMovCassa,
  addVoceCassa,
  addSpesa,
  deleteSpesa,
  deleteMovCassa,
} from "../store/actions/handleHome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { stateChange } from "../functions/stateChange";
import { CeckConnection } from "../functions/ConnectionStatius";
import { searchRubrica } from "../functions/searchRubrica";
import { statusCheck, SUBSCRIPTION_STATUS } from "../functions/statusCheck";
import soudError from "../sound/error.wav";
import VoceCassaCard from "../components/homeComponent/VoceCassaCard";
import SignleCreditCard from "../components/homeComponent/SignleCreditCard";
import SingleMovCassa from "../components/homeComponent/SingleMovCassa";
import { StyleColorObject } from "../functions/StyleColorObject";
import ColumService from "../components/homeComponent/ColumService";
import ColumServiceCredit from "../components/homeComponent/ColumServiceCerdit";
import ColumServiceVociCassa from "../components/homeComponent/ColumServiceVociCassa";
import DataPikers from "../components/globalComponent/DataPikers";
import Navbar from "../components/Navbar";
import "../styles/Price.scss";
import SweetAlert2 from "react-sweetalert2";
import Autocomplete from "@mui/joy/Autocomplete";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AddCardIcon from "@mui/icons-material/AddCard";
import MessageAlert from "../components/MessageAlert";
import { collection, getDocs } from "firebase/firestore";
import db from "../Firebase-config";
import { logoutRoot } from "../store/actions/handelAuth";

registerLocale("it", it);
function Home() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const userToken = useSelector((state) => state.authReducer.token);
  const startDate = useSelector((state) => state.dataReducer.startDate);
  const [swalProps, setSwalProps] = useState({});
  const [items, setItems] = useState([]);
  const [price, setPrice] = useState("");
  const [showCrediti, setShowCrediti] = useState(true);
  const [showMovCassa, setShowMovCassa] = useState(true);
  const [showSpese, setShowSpese] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [subscriptionMessage, setSubscriptionMessage] = useState(null);
  const [message, setMessage] = useState("");

  const {
    error,
    loadingFetch,
    loadingCredit,
    loadingMovCassa,
    loadingSpese,
    saveVociCassa,
    totaleCassa,
    creditiGiorno,
    movimentiCassa,
    arrayVociCassa,
    aggioGiornaliero,
    totaleCreditiGiorno,
    totaleSpese,
    arraySpese,
    totaleMovimentiCassa,
  } = useSelector((state) => state.homeReducers);

  // styles
  const stylesTCG = StyleColorObject(totaleCreditiGiorno);
  const stylesTMC = StyleColorObject(totaleMovimentiCassa);
  const stylesTC = StyleColorObject(totaleCassa);
  const stylesAG = StyleColorObject(aggioGiornaliero);
  const stylesTS = StyleColorObject(totaleSpese);
  // ----------------------------------------------------------------

  // audio errore
  const audio = new Audio(soudError);
  audio.loop = false;
  const playError = () => {
    audio.loop = false;
    audio.play();
  };
  // ----------------------------------------------------------------

  // tost message errore
  const notifyError = () =>
    toast.error(message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // -----------------------------
  // tost message errore
  const notifySuccess = () =>
    toast.success("Salvato con sucesso !!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // -----------------------------

  const createSubscriptionMessage = (response) => {
    let messageTemplate = '<div style="text-align: center; font-family: Arial, sans-serif;">';

    // Icona di warning
    messageTemplate += `
      <div style="margin-bottom: 15px;">
        <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZjk4MDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBkPSJNMTAgMTNoNHYzaC00eiI+PC9wYXRoPjxwYXRoIGQ9Ik0xMiAyYTEwIDEwIDAgMSAwIDAgMjAgMTAgMTAgMCAxIDAgMC0yMHoiPjwvcGF0aD48cGF0aCBkPSJNMTIgNnYzIj48L3BhdGg+PC9zdmc+" 
        alt="warning" style="width: 64px; height: 64px;" />
      </div>`;

    // Titolo e contenuto in base al codice di stato
    switch (response.code) {
      case SUBSCRIPTION_STATUS.EXPIRING_SOON:
        const days = response.details.daysRemaining;
        messageTemplate += `
          <div style="margin-bottom: 20px; color: #e65100; font-size: 20px; font-weight: bold;">
            Il tuo abbonamento sta per scadere
          </div>
          <div style="margin-bottom: 15px; color: #424242; font-size: 16px;">
            Mancano <span style="color: #ff5722; font-weight: bold; font-size: 20px;">
            ${days} ${days === 1 ? "giorno" : "giorni"}</span> alla scadenza.
          </div>`;
        break;

      case SUBSCRIPTION_STATUS.EXPIRED:
        messageTemplate += `
          <div style="margin-bottom: 20px; color: #d32f2f; font-size: 20px; font-weight: bold;">
            Il tuo abbonamento è scaduto
          </div>
          <div style="margin-bottom: 15px; color: #424242; font-size: 16px;">
            Il tuo abbonamento è scaduto.
            <br>Rinnova ora per continuare ad utilizzare tutti i servizi.
          </div>`;
        break;

      case SUBSCRIPTION_STATUS.NO_SUBSCRIPTION:
        messageTemplate += `
          <div style="margin-bottom: 20px; color: #d32f2f; font-size: 20px; font-weight: bold;">
            Nessun abbonamento attivo
          </div>
          <div style="margin-bottom: 15px; color: #424242; font-size: 16px;">
            Non hai un abbonamento attivo.
            <br>Sottoscrivi un piano per accedere a tutti i servizi.
          </div>`;
        break;

      case SUBSCRIPTION_STATUS.ERROR:
        messageTemplate += `
          <div style="margin-bottom: 20px; color: #d32f2f; font-size: 20px; font-weight: bold;">
            Errore durante la verifica
          </div>
          <div style="margin-bottom: 15px; color: #424242; font-size: 16px;">
            Si è verificato un errore durante la verifica del tuo abbonamento.
            <br>Per favore, riprova più tardi o contatta l'assistenza.
          </div>`;
        break;
    }

    // Aggiungi il pulsante di azione se necessario
    // if (response.details?.action) {
    //   const buttonText = response.details.action === "subscribe" ? "Sottoscrivi un Piano" : "Rinnova Abbonamento";
    //   messageTemplate += `
    //     <div style="margin-top: 20px;">
    //       <a href="/pricing" style="background-color: #2196f3; color: white; padding: 12px 24px;
    //         text-decoration: none; border-radius: 5px; font-weight: bold; display: inline-block;
    //         transition: background-color 0.3s;">
    //         ${buttonText}
    //       </a>
    //     </div>`;
    // }

    messageTemplate += "</div>";
    return messageTemplate;
  };

  const checkSubscriptionStatus = async () => {
    try {
      const response = await statusCheck(email);

      // Se l'abbonamento è scaduto o non esiste, effettua il logout
      if (
        !response.status &&
        (response.code === SUBSCRIPTION_STATUS.EXPIRED || response.code === SUBSCRIPTION_STATUS.NO_SUBSCRIPTION)
      ) {
        dispatch(logoutRoot());
        window.location.reload();
        return false;
      }

      // Se c'è un messaggio da mostrare (scadenza imminente o errore)
      if (response.code !== SUBSCRIPTION_STATUS.ACTIVE) {
        const messageTemplate = createSubscriptionMessage(response);
        console.log(messageTemplate);
        setCurrentMessage({
          id: "subscription-warning",
          title: response.message,
          message: messageTemplate,
          type: response.details.severity === "high" ? "error" : "warning",
          forceActive: true,
          html: messageTemplate,
        });
        setShowMessage(true);
      }

      return true;
    } catch (error) {
      console.error("Errore durante il controllo dell'abbonamento:", error);
      return false;
    }
  };

  useEffect(() => {
    if (email) {
      checkSubscriptionStatus();
    }
  }, [email]);

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    const checkStatus = async () => {
      if (token) {
        // Check subscription status
        const isSubscriptionValid = await checkSubscriptionStatus();
        if (!isSubscriptionValid) return;

        stateChange();
        dispatch(errorReset());

        // Se non c'è un messaggio di abbonamento, controlla gli altri messaggi
        if (!currentMessage?.id?.includes("subscription")) {
          await checkForMessages();
        }
      }
    };

    checkStatus();
  }, [
    dispatch,
    startDate,
    token,
    totaleCassa,
    totaleCreditiGiorno,
    totaleMovimentiCassa,
    totaleSpese,
    arrayVociCassa,
    aggioGiornaliero,
    email,
  ]);

  useEffect(() => {
    const checkMessages = async () => {
      // First check subscription message
      if (subscriptionMessage) {
        setCurrentMessage(subscriptionMessage);
        setShowMessage(true);
        return;
      }

      // If no subscription warning, check regular messages
      await checkForMessages();
    };

    checkMessages();
  }, [subscriptionMessage]);

  const checkForMessages = async () => {
    try {
      // Verifica se l'utente ha già visto il messaggio
      const seenMessages = JSON.parse(localStorage.getItem("seenMessages") || "[]");

      // Recupera il messaggio dal database
      const messagesRef = collection(db, "message");
      const messageSnapshot = await getDocs(messagesRef);

      if (!messageSnapshot.empty) {
        const messages = [];
        messageSnapshot.forEach((doc) => {
          const messageData = doc.data();
          // Verifica se il messaggio è di test e se siamo in ambiente di sviluppo
          const isTestMessage = messageData.isTest && window.location.hostname === "localhost";
          // Verifica se il messaggio è attivo e (non è stato visto O è forceActive) e se non è un messaggio di test o se lo è ma siamo in localhost
          if (
            messageData.active &&
            (!seenMessages.includes(doc.id) || messageData.forceActive) &&
            (!messageData.isTest || isTestMessage)
          ) {
            messages.push({
              id: doc.id,
              ...messageData,
            });
          }
        });

        // Ordina i messaggi per data e priorità
        if (messages.length > 0) {
          const sortedMessages = messages.sort((a, b) => {
            // Prima ordina per forceActive (i messaggi forzati hanno priorità)
            if (a.forceActive && !b.forceActive) return -1;
            if (!a.forceActive && b.forceActive) return 1;
            // Poi per timestamp
            return (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0);
          });
          setCurrentMessage(sortedMessages[0]);
          setShowMessage(true);
        }
      }
    } catch (error) {
      console.error("Errore nel recupero dei messaggi:", error);
    }
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const handleNeverShowAgain = () => {
    if (currentMessage) {
      const seenMessages = JSON.parse(localStorage.getItem("seenMessages") || "[]");
      seenMessages.push(currentMessage.id);
      localStorage.setItem("seenMessages", JSON.stringify(seenMessages));
    }
    setShowMessage(false);
  };

  // se non è presente il token allora il cliente  non è registrato oppure non è loggato
  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  // resetta errori
  const alterShow = () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----
    dispatch(errorReset());
  };

  const handleAddName = async () => {
    const { value: formValues } = await Swal.fire({
      title: "NUOVO NOME",
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="cognome">' +
        '<input id="swal-input2" class="swal2-input" placeholder="nome">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value.trim(), document.getElementById("swal-input2").value.trim()];
      },
    });
    if (formValues) {
      if (!formValues[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del COGNOME è vuoto",
        });
        return;
      }
      if (!formValues[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del NOME è vuoto",
        });
        return;
      }
      dispatch(addName(formValues[0].trim(), formValues[1].trim()));
    }
  };

  const handleAddCredit = async () => {
    const arrayRubrica = await searchRubrica();
    const listContact = [];
    arrayRubrica.forEach((element) => {
      listContact.push({
        id: element.cognome + "-" + element.nome,
        label: element.cognome.toUpperCase() + " " + element.nome.toUpperCase(),
        name: element.nome,
        surname: element.cognome,
      });
    });
    setItems(listContact);

    setSwalProps({
      show: true,
      title: "Nuovo Credito",
      focusConfirm: false,
      showCancelButton: true,
    });
  };

  const handleAddMovCassa = async () => {
    const { value: formValues2 } = await Swal.fire({
      title: "Nuovo Movimento Cassa",
      html:
        `<p class="swal2-header">Inserisci l'importo del movimento</p>` +
        '<input id="swal-input1" class="swal2-input" type="number" placeholder="es. -100 o  + 100">' +
        ` <p class="swal2-header">inserisci la cusale del movimento </p>` +
        '<input id="swal-input2" class="swal2-input" type="textarea" placeholder="es. soldi presi dalla cassa">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value, document.getElementById("swal-input2").value.trim()];
      },
    });

    if (formValues2) {
      if (!formValues2[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito l'importo, devi obbligatoriamente inserire un importo",
        });
        return;
      }
      if (!formValues2[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito una causale, devi obbligatoriamente inserire una causale",
        });
        return;
      }
      if (formValues2[0] && formValues2[1]) {
        dispatch(addMovCassa(formValues2[0], formValues2[1], startDate));
      }
    }
  };

  const handleAddSpesa = async () => {
    const { value: formValues3 } = await Swal.fire({
      title: "Nuova Spesa",
      html:
        `<p class="swal2-header">Inserisci l'importo della Spesa</p>` +
        '<input id="swal-input1" class="swal2-input" type="number" placeholder="es. -100 o  + 100">' +
        `<p class="swal2-header">inserisci la causale della spesa</p>` +
        '<input id="swal-input2" class="swal2-input" type="textarea" placeholder="es. Fornitore bevande">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value, document.getElementById("swal-input2").value.trim()];
      },
    });

    if (formValues3) {
      if (!formValues3[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito l'importo, devi obbligatoriamente inserire un importo",
        });
        return;
      }
      if (!formValues3[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito una causale, devi obbligatoriamente inserire una causale",
        });
        return;
      }
      if (formValues3[0] && formValues3[1]) {
        dispatch(addSpesa(formValues3[0], formValues3[1], startDate));
      }
    }
  };

  const handleAddVociCassa = async () => {
    const { value: formValues3 } = await Swal.fire({
      title: "Nuova Voce",
      html:
        '<p class="swal2-header">Inserisci il nome della voce</p>' +
        '<input id="swal-input1" class="swal2-input" type="text" placeholder="es. Distributore Presi ">' +
        `<p class="swal2-header"> se è quantificabile insiersci la percentuale di guadagno relativa a questa voce altrimenti inserisci '0'</p>` +
        '<input id="swal-input3" class="swal2-input" type="number" placeholder="es. 10 or 0 ">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value.trim(), document.getElementById("swal-input3").value];
      },
    });

    if (formValues3) {
      if (!formValues3[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito la percentuale di guadagno, se non è quantificabile inserisci 0",
        });
        return;
      }
      if (formValues3[0] && formValues3[1]) {
        dispatch(
          addVoceCassa(formValues3[0].replaceAll(" ", "-").toLowerCase(), formValues3[0], Number(formValues3[1]), startDate)
        );
      }
    }
  };
  const renderSpinner = () => {
    return (
      <div className="local-bootstrap">
        <div className="spinner-center mt-5">
          <div className="spinner-border" style={{ width: "3rem", height: "3rem", color: "#4c79d8" }} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  };

  const renderBodyCrediti = () => {
    if (!creditiGiorno.includes("false")) {
      return creditiGiorno.map((person) => {
        return <SignleCreditCard key={person.id} person={person} />;
      });
    } else {
      return;
    }
  };

  const renderBodyMovCassa = () => {
    if (!movimentiCassa.includes("false")) {
      return movimentiCassa.map((mov) => {
        return <SingleMovCassa key={mov.id} mov={mov} day={startDate} deleteFunction={deleteMovCassa} />;
      });
    } else {
      return;
    }
  };
  const renderBodySpesa = () => {
    if (!arraySpese.includes("false")) {
      return arraySpese.map((mov) => {
        return <SingleMovCassa key={mov.id} mov={mov} day={startDate} deleteFunction={deleteSpesa} />;
      });
    } else {
      return;
    }
  };

  const renderBodyVociCassa = () => {
    return arrayVociCassa.map((vociCassa) => {
      return (
        <VoceCassaCard
          key={vociCassa.alias}
          id={vociCassa.alias}
          nome={vociCassa.nome}
          valore={vociCassa.valore}
          percentualeQuadagno={vociCassa.percentualeQuadagno}
          day={startDate}
        />
      );
    });
  };
  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="home">
        <div className="home-content-wrapper">
          <ColumServiceCredit
            value={totaleCreditiGiorno}
            style={stylesTCG}
            addCreditFunction={handleAddCredit}
            addNameFunction={handleAddName}
            renderBodyFunction={loadingFetch || loadingCredit ? renderSpinner : renderBodyCrediti}
            showContent={showCrediti}
            onToggleVisibility={() => setShowCrediti(!showCrediti)}
          />
          <ColumService
            titleHead={`Totale Mov Cassa`}
            ico={<BsIcons.BsArrowDownUp />}
            title={"Mov Cassa"}
            value={totaleMovimentiCassa}
            style={stylesTMC}
            addFunction={handleAddMovCassa}
            renderBodyFunction={loadingFetch || loadingMovCassa ? renderSpinner : renderBodyMovCassa}
            dataTip={`In questa colonna sono rappresentati <br /> tutti i movimenti fatti nella cassa`}
            dataTipButton={`Premi questo tasto se vuoi <br /> aggiungere un movimento`}
            icon={<ImportExportIcon sx={{ color: "#fff", fontSize: "50px" }} />}
            showContent={showMovCassa}
            onToggleVisibility={() => setShowMovCassa(!showMovCassa)}
          />
          <ColumService
            titleHead={`Totale Spese`}
            ico={<BsIcons.BsCashStack />}
            title={"Spese"}
            value={totaleSpese}
            style={stylesTS}
            addFunction={handleAddSpesa}
            renderBodyFunction={loadingFetch || loadingSpese ? renderSpinner : renderBodySpesa}
            dataTip={`In questa colonna sono rappresentate <br /> tutte le spese del giorno`}
            dataTipButton={`Premi questo tasto se vuoi <br /> aggiungere una spesa`}
            icon={<AddCardIcon sx={{ color: "#fff", fontSize: "50px" }} />}
            showContent={showSpese}
            onToggleVisibility={() => setShowSpese(!showSpese)}
          />
          <ColumServiceVociCassa
            datePicker={<DataPikers fetchFunctions={fetchDayTotal} />}
            value={totaleCassa}
            style={stylesTC}
            styleAG={stylesAG}
            addFunction={handleAddVociCassa}
            renderBodyFunction={loadingFetch ? renderSpinner : renderBodyVociCassa}
            aggioGiornaliero={aggioGiornaliero}
          />
        </div>
        {error ? (playError(), notifyError(), alterShow()) : null}
        {saveVociCassa ? (notifySuccess(), alterShow(), dispatch(errorReset())) : null}
        <ToastContainer
          position="top-letf"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SweetAlert2
          {...swalProps}
          preConfirm={(res) => {
            const importo = document.getElementById("input-price").value;

            const val = JSON.parse(localStorage.getItem("value"));
            dispatch(addCredito(val.surname, val.name, Number(importo), startDate));
            setPrice("");
          }}
          onConfirm={(res) => {
            setSwalProps({
              show: false,
            });
            setPrice("");
          }}
          onResolve={(res) => {
            setSwalProps({
              show: false,
            });
            setPrice("");
          }}
          didClose={(res) => {
            setSwalProps({
              show: false,
            });
            setPrice("");
          }}
        >
          <p style={{ marginBottom: "0px", fontSize: "18px" }} class="swal2-header">
            Seleziona il nome del creditore dal menu a tendida
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Autocomplete
              placeholder="Scegli il nome"
              options={items}
              sx={{ width: 250 }}
              menuStyle={{
                borderRadius: "3px",
                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                background: "rgba(255, 255, 255, 0.9)",
                padding: "2px 0",
                fontSize: "90%",
                position: "fixed",
                overflow: "auto",
                maxHeight: "50%", // TODO: don't cheat, let it flow to the bottom
                zIndex: 100,
              }}
              getOptionLabel={(user) => user.label}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={(event, newValue) => {
                localStorage.setItem("value", JSON.stringify(newValue));
              }}
            />
          </div>
          <p style={{ fontSize: "18px", marginTop: "40px" }} class="swal2-header">
            inserisci l'importo del credito
          </p>
          <input
            id="input-price"
            class="swal2-input"
            type="number"
            required
            placeholder="importo"
            onInput={(e) => {
              setPrice(e.target.value);
            }}
            value={price}
            style={{ marginTop: "20px" }}
          />
        </SweetAlert2>
        <MessageAlert
          message={currentMessage}
          open={showMessage}
          onClose={handleCloseMessage}
          onNeverShowAgain={subscriptionMessage ? undefined : handleNeverShowAgain}
        />
      </div>
    </>
  );
}

export default Home;
