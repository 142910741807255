import React from "react";
import { useState, useEffect } from "react";
import "./VoceCassaCard.scss";
import "../../styles/Glassmorphism.css";
import { addVoceCassaDelete, addVoceCassaSave } from "../../store/actions/handleHome";
import { useDispatch, useSelector } from "react-redux";
import { StyleColorObject } from "../../functions/StyleColorObject";
import Swal from "sweetalert2";
import IcoDelete from "../globalComponent/IcoDelete";
import IcoSave from "../globalComponent/IcoSave";
import { Tooltip } from "@mui/material";

function VoceCassaCard({ id, nome, day }) {
  const { loadingVociCassa, arrayVociCassa } = useSelector((state) => state.homeReducers);

  const jsonCard = arrayVociCassa.filter((item) => item.alias === id);
  const percentualeQuadagno = Number(jsonCard[0].percentualeQuadagno);
  const valore = Number(jsonCard[0].valore);

  const [val, setVal] = useState(valore);

  useEffect(() => {
    setVal(valore);
  }, [valore]);

  const dispatch = useDispatch();

  const styles = StyleColorObject(val);

  const handleInput = (e) => {
    setVal(e.target.value);
    e.preventDefault();
  };

  const handleSave = () => {
    dispatch(addVoceCassaSave(id, Number(val), day));
  };

  const handleDelete = (e) => {
    dispatch(addVoceCassaDelete(id, day));
  };

  const handelConfirmDelete = () => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare questa voce ??`,
      text: ` "${nome.toUpperCase()}" verra eliminata dalla lista delle voci`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(handleDelete);
      }
    });
  };

  // Calcolo del guadagno
  const guadagno = Number(percentualeQuadagno) === 0 ? 0 : ((Number(percentualeQuadagno) * Number(val)) / 100).toFixed(2);
  const tooltipText =
    Number(val) === 0
      ? "Inserisci un valore"
      : Number(percentualeQuadagno) === 0
      ? "Non hai inserito una percentuale di guadagno, quindi non è quantificabile"
      : `Il tuo guadagno è di ${guadagno}€`;

  return (
    <div className="voce-cassa-card">
      {/* Nome della voce */}
      <Tooltip title={nome} placement="top" arrow>
        <div className="nome">{nome}</div>
      </Tooltip>

      {/* Contenuto con input e azioni */}
      <div className="card-content">
        <div className="input-container">
          <Tooltip title={tooltipText} placement="top" arrow>
            <input
              className="input-value"
              type="number"
              value={val}
              onChange={handleInput}
              style={{ color: styles.color }}
              placeholder="0"
            />
          </Tooltip>
        </div>
        <div className="card-actions">
          <IcoSave handleSave={!loadingVociCassa ? handleSave : null} />
          <IcoDelete handelConfirmDelete={!loadingVociCassa ? handelConfirmDelete : null} />
        </div>
      </div>
    </div>
  );
}

export default VoceCassaCard;
