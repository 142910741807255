import React from "react";
import "./ColumService.css";
import "./ColumServiceCredit.css";
import "../../styles/Glassmorphism.css";
import { Divider, Typography, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BlockIcon from "@mui/icons-material/Block";

function ColumService({ titleHead, title, value, addFunction, renderBodyFunction, icon, showContent, onToggleVisibility }) {
  // Formatta il valore totale per renderlo più leggibile
  const formatTotalValue = (value) => {
    const numValue = parseFloat(value);
    return numValue.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="movimenti-cassa">
      <div className="div_ glass-card">
        {/* Header compatto con pulsante di aggiunta */}
        <div className="header-with-action" style={{ position: "relative" }}>
          <Tooltip title={showContent ? "Nascondi Contenuto" : "Mostra Contenuto"} placement="left" arrow>
            <IconButton
              className="header-action-button accent-button"
              onClick={onToggleVisibility}
              size="small"
              sx={{
                position: "absolute",
                top: "-8px",
                right: "-8px",
                padding: "2px",
                width: "20px",
                height: "20px",
                minWidth: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              {showContent ? <VisibilityIcon sx={{ fontSize: 12 }} /> : <VisibilityOffIcon sx={{ fontSize: 12 }} />}
            </IconButton>
          </Tooltip>
          <div className="header-left">
            <div className="compact-header">
              <div className="compact-header-icon">
                <AccountBalanceIcon sx={{ fontSize: 20, color: "var(--secondary-color)" }} />
              </div>
              <div className="compact-header-content">
                <Typography variant="subtitle2" className="compact-header-title">
                  {titleHead}
                </Typography>
                <Typography
                  variant="h6"
                  className="compact-header-value"
                  sx={{
                    color: value < 0 ? "var(--negative-color)" : value > 0 ? "var(--positive-color)" : "var(--secondary-color)",
                    fontWeight: 600,
                  }}
                >
                  {formatTotalValue(value)} €
                </Typography>
              </div>
            </div>
          </div>
          <Tooltip title={`Aggiungi ${title}`} placement="top" arrow>
            <IconButton className="header-action-button" onClick={addFunction} size="small">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>

        <Divider sx={{ margin: "8px 0", opacity: 0.2 }} />
        {showContent ? (
          <div className="body-mov-cassa">{renderBodyFunction()}</div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              color: "#ffffff",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              margin: "10px",
              borderRadius: "8px",
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={onToggleVisibility}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <BlockIcon sx={{ fontSize: 20 }} />
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Contenuto Nascosto
              </Typography>
            </div>
            <Typography variant="caption" sx={{ opacity: 0.7 }}>
              clicca per mostrare il contenuto
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default ColumService;
