import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import Logout from "./pages/Logout";
import Registrazione from "./pages/Registrazione";
import ListaCrediti from "./pages/ListaCrediti";
import ContactForm from "./pages/ContactForm";
import Report from "./pages/Report";
import PDFView from "./pages/Pdfview";
import Price from "./pages/Price";
import VoiceSetting from "./pages/VoiceSetting";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { authCheck } from "./store/actions/handelAuth";
import { PlanData } from "../src/components/priceContainer/PlanData";
import Navbar from "./components/Navbar";
import Help from "./pages/Help";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

// Styled component for the main content
const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  marginLeft: "0", // Rimuovo il margine fisso
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

function App() {
  const userToken = useSelector((state) => state.authReducer.token);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(authCheck());
  }, [dispatch]);

  // Don't show navbar on auth and registration pages
  const hideNavbar = ["/auth", "/registrazione"].includes(location.pathname);

  return (
    <Box sx={{ display: "flex" }}>
      {userToken && !hideNavbar && <Navbar />}

      <MainContent>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/auth" component={Auth} />
          <Route path="/logout" component={Logout} />
          <Route path="/lista-crediti" component={ListaCrediti} />
          <Route path="/report/pdf" component={PDFView} />
          <Route path="/report" component={Report} />
          <Route path="/registrazione" component={Registrazione} />
          <Route path="/help" component={Help} />
          <Route path="/contactform" component={ContactForm} />
          <Route path="/voice-setting" component={VoiceSetting} />
          <Route path="/price" component={Price} />
          {PlanData.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              component={() => {
                window.location.href = item.link;
                return null;
              }}
            />
          ))}
        </Switch>
      </MainContent>
    </Box>
  );
}

export default App;
