import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./SidbarData";
import "../styles/Navbar.css";
import "../styles/Price.scss";
import "../styles/Price.css";
import "../styles/Glassmorphism.css";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: "var(--secodary-color)",
  borderRight: "none",
  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  background: "var(--secodary-color)",
  borderRight: "none",
  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Navbar() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("/");

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer variant="permanent" open={open} className="modern-sidebar">
      <DrawerHeader>
        {open ? (
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src="https://i.ibb.co/wCxbSJy/logo512.png"
                sx={{
                  width: 32,
                  height: 32,
                  marginRight: "10px",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  color: "#fff",
                  fontWeight: 600,
                  fontFamily: "'Inter', 'Poppins', sans-serif",
                  fontSize: "18px",
                }}
              >
                FlowMoney
              </Typography>
            </Box>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            onClick={handleDrawerOpen}
            sx={{
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </DrawerHeader>

      <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)", margin: "0 16px", mb: 2 }} />

      <List sx={{ px: 1 }}>
        {SidebarData.map((item, index) => {
          const isActive = activeItem === item.path;

          return (
            <Tooltip title={open ? "" : item.title} placement="right" key={index} arrow>
              <ListItem disablePadding sx={{ display: "block", mb: 1 }}>
                <Link to={item.path} style={{ textDecoration: "none" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      borderRadius: "12px",
                      backgroundColor: isActive ? "var(--accent-color)" : "transparent",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: isActive ? "var(--accent-color)" : "rgba(255, 255, 255, 0.1)",
                        transform: "translateY(-2px)",
                        boxShadow: isActive ? "0 4px 12px rgba(253, 140, 34, 0.4)" : "none",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: isActive ? "#ffffff" : "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: isActive ? "#ffffff" : "rgba(255, 255, 255, 0.7)",
                        "& .MuiTypography-root": {
                          fontWeight: isActive ? 600 : 400,
                          fontSize: "0.95rem",
                        },
                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>

      <Box sx={{ flexGrow: 1 }} />

      <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)", margin: "16px" }} />

      <Tooltip title={open ? "" : "Logout"} placement="right" arrow>
        <ListItem disablePadding sx={{ display: "block", px: 1, mb: 2 }}>
          <Link to="/logout" style={{ textDecoration: "none" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                borderRadius: "12px",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "rgba(244, 67, 54, 0.2)",
                  transform: "translateY(-2px)",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "#F44336",
                }}
              >
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                sx={{
                  opacity: open ? 1 : 0,
                  color: "#F44336",
                  "& .MuiTypography-root": {
                    fontWeight: 500,
                    fontSize: "0.95rem",
                  },
                }}
              />
            </ListItemButton>
          </Link>
        </ListItem>
      </Tooltip>
    </Drawer>
  );
}

export default Navbar;
