import axios from "axios";
import { PlanData } from "../components/priceContainer/PlanData";

// Codici di stato dell'abbonamento
export const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE", // Abbonamento attivo e non in scadenza
  EXPIRING_SOON: "EXPIRING_SOON", // Abbonamento attivo ma in scadenza
  EXPIRED: "EXPIRED", // Abbonamento scaduto
  NO_SUBSCRIPTION: "NO_SUBSCRIPTION", // Nessun abbonamento trovato
  ERROR: "ERROR", // Errore durante la verifica
};

export const statusCheck = async (email) => {
  try {
    const res = await axios.get(`https://server-flowmoney.oa.r.appspot.com/status?email=${email}`);
    const resFindPlans = [];

    if (!res.data.data || !Array.isArray(res.data.data)) {
      return {
        status: false,
        code: SUBSCRIPTION_STATUS.ERROR,
        message: "Errore nel formato dei dati ricevuti dal server",
        details: null,
      };
    }

    res.data.data.forEach((plans) => {
      if (PlanData.some((item) => item["path"] === plans.items.data[0].price.id)) {
        resFindPlans.push({
          status: plans.status,
          cancelAt: plans.cancel_at,
          currentPeriodEnd: plans.current_period_end,
          planName: plans.items.data[0].price.nickname || "Piano standard",
        });
      }
    });

    // Se non ci sono piani attivi
    if (resFindPlans.length === 0) {
      return {
        status: false,
        code: SUBSCRIPTION_STATUS.NO_SUBSCRIPTION,
        message: "Nessun abbonamento attivo",
        details: {
          action: "subscribe",
          severity: "high",
        },
      };
    }

    // Trova il piano attivo più recente
    const activePlan = resFindPlans.find((plan) => plan.status === "active");
    if (!activePlan) {
      return {
        status: false,
        code: SUBSCRIPTION_STATUS.EXPIRED,
        message: "Abbonamento scaduto",
        details: {
          action: "renew",
          severity: "high",
          planName: resFindPlans[0].planName,
        },
      };
    }

    // Calcola i giorni rimanenti
    const expirationDate = new Date(activePlan.currentPeriodEnd * 1000);
    const now = new Date();
    const daysUntilExpiration = Math.ceil((expirationDate - now) / (1000 * 60 * 60 * 24));

    // Se mancano 30 giorni o meno alla scadenza
    if (daysUntilExpiration <= 30) {
      return {
        status: true,
        code: SUBSCRIPTION_STATUS.EXPIRING_SOON,
        message: "Abbonamento in scadenza",
        details: {
          daysRemaining: daysUntilExpiration,
          expirationDate: expirationDate.toISOString(),
          planName: activePlan.planName,
          action: "renew",
          severity: "medium",
        },
      };
    }

    // Abbonamento attivo e non in scadenza
    return {
      status: true,
      code: SUBSCRIPTION_STATUS.ACTIVE,
      message: "Abbonamento attivo",
      details: {
        daysRemaining: daysUntilExpiration,
        planName: activePlan.planName,
        expirationDate: expirationDate.toISOString(),
        severity: "low",
      },
    };
  } catch (error) {
    console.error("Errore durante la verifica dell'abbonamento:", error);
    return {
      status: false,
      code: SUBSCRIPTION_STATUS.ERROR,
      message: "Errore durante la verifica dell'abbonamento",
      details: {
        error: error.message,
        serverError: error.response?.data,
        severity: "high",
      },
    };
  }
};
