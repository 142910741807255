import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import "./IcoDelete.css";

function IcoDelete({ handelConfirmDelete }) {
  return (
    <Tooltip title="Elimina" placement="top" arrow>
      <IconButton className="delete-button" onClick={handelConfirmDelete} size="small" aria-label="Elimina">
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

export default IcoDelete;
