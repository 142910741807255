import React from "react";
import HeadTot from "../globalComponent/HeadTot";
import "./ColumServiceCredit.css";
import "../../styles/Price.scss";
import "../../styles/Glassmorphism.css";
import { Divider, Typography, IconButton, Tooltip } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddIcon from "@mui/icons-material/Add";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BlockIcon from "@mui/icons-material/Block";

function ColumServiceCerdit({
  value,
  style,
  addCreditFunction,
  addNameFunction,
  renderBodyFunction,
  showContent,
  onToggleVisibility,
}) {
  // Formatta il valore totale per renderlo più leggibile
  const formatTotalValue = (value) => {
    const numValue = parseFloat(value);
    return numValue.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="crediti">
      <div className="head-mobile-crediti">
        <HeadTot
          title={`Totale Crediti`}
          value={value}
          style={style}
          icon={<GroupAddIcon sx={{ color: "#fff", fontSize: "50px" }} />}
          clickAction={addCreditFunction}
        />
      </div>

      <div className="div_ glass-card">
        {/* Header con pulsanti di azione */}
        <div className="header-with-action" style={{ position: "relative" }}>
          <Tooltip title={showContent ? "Nascondi Contenuto" : "Mostra Contenuto"} placement="left" arrow>
            <IconButton
              className="header-action-button accent-button"
              onClick={onToggleVisibility}
              size="small"
              sx={{
                position: "absolute",
                top: "-8px",
                right: "-8px",
                padding: "2px",
                width: "20px",
                height: "20px",
                minWidth: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              {showContent ? <VisibilityIcon sx={{ fontSize: 12 }} /> : <VisibilityOffIcon sx={{ fontSize: 12 }} />}
            </IconButton>
          </Tooltip>
          <div className="header-left">
            <div className="compact-header">
              <div className="compact-header-icon">
                <AccountBalanceWalletIcon sx={{ fontSize: 20, color: "var(--accent-color)" }} />
              </div>
              <div className="compact-header-content">
                <Typography variant="subtitle2" className="compact-header-title">
                  Totale Crediti
                </Typography>
                <Typography
                  variant="h6"
                  className="compact-header-value"
                  sx={{
                    color: style?.color || "var(--accent-color)",
                    fontWeight: 600,
                  }}
                >
                  {formatTotalValue(value)} €
                </Typography>
              </div>
            </div>
          </div>
          <div className="header-actions">
            <Tooltip title="Aggiungi Credito" placement="top" arrow>
              <IconButton className="header-action-button accent-button" onClick={addCreditFunction} size="small">
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Aggiungi Nome" placement="top" arrow>
              <IconButton className="header-action-button primary-button" onClick={addNameFunction} size="small">
                <PersonAddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Divider sx={{ margin: "8px 0", opacity: 0.2 }} />

        {showContent ? (
          <div className="body-crediti">{renderBodyFunction()}</div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              color: "#ffffff",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              margin: "10px",
              borderRadius: "8px",
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={onToggleVisibility}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <BlockIcon sx={{ fontSize: 20 }} />
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Contenuto Nascosto
              </Typography>
            </div>
            <Typography variant="caption" sx={{ opacity: 0.7 }}>
              clicca per mostrare il contenuto
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default ColumServiceCerdit;
