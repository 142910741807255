import React from "react";
import Chart from "react-apexcharts";
import "./CardSingleStatsChart.css";
import { Paper, Typography, Box, Divider } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

function CardSingleStatsChart({ option, name, percent }) {
  // Verifica se ci sono dati validi
  const hasValidData = option.series[0].data && option.series[0].data.length > 0;

  // Calcola la somma solo se ci sono dati validi
  const tot = hasValidData ? option.series[0].data.reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0) : 0;

  // Calcola la media solo se ci sono dati validi e la lunghezza è maggiore di zero
  const mediaGior = hasValidData && option.series[0].data.length > 0 ? (tot / option.series[0].data.length).toFixed(2) : "0.00";

  return (
    <Paper elevation={2} className="chart-card">
      <Box className="chart-header">
        <Typography variant="h6" className="chart-title">
          {name}
        </Typography>
      </Box>

      <Divider className="chart-divider" />

      <Box className="chart-content">
        <Chart options={option.options} series={option.series} type="line" width={350} height={200} />
      </Box>

      <Box className="chart-footer">
        <Box className="stat-item">
          <TrendingUpIcon className="stat-icon" />
          <Typography variant="body1" className="stat-label">
            Media Giornaliera:
          </Typography>
          <Typography variant="h6" className="stat-value">
            {mediaGior} €
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export default CardSingleStatsChart;
